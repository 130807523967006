import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/', 
    name: 'Accueil',
    //path: '/',   pour choisir la route de départ par défaut
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Accueil.vue')
  }, {
    path: '/film/:id',
    name: 'Film',

    component: () => import('../views/Film.vue')
  }, {
    path: '/favoris',
    name: 'Favoris',

    component: () => import('../views/Favoris.vue')
  }, {
    path: '/filmsavoir',
    name: 'Filmsavoir',

    component: () => import('../views/Filmsavoir.vue')
  }, {
    path: '/login',
    name: 'Login',

    component: () => import('../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
