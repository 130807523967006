<template>
  <div id="app" class="relative">
    <div class="hauteur">
    <p class="titre">FAKEFLIX</p>
   <navebar v-if="$store.state.login"></navebar>
    
    <router-view />
    </div>
    <footer class="absolute">
      <p>Fakeflix © 2021 - Powered by <a href="https://www.themoviedb.org/?language=fr" target="blank">themoviedb</a></p>
    </footer>
  </div>
  
</template>

<script>
import navebar from "@/components/Navebar";


export default {
  components: {
    navebar,
  },
  created:function() {
    if (!this.$store.login) {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>


<style>
.hauteur{
  min-height: 650px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/*
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}*/
.titre {
  color: red;
  font-size: 60px;
}
body {
  background-color: black;
  max-width: 80%;
  margin: auto;

}

.relative{
  position: relative;
}
/* .absolute{
  position: absolute;
  bottom: 0%;
} */
</style>
