import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export let store = new Vuex.Store({
  state: {
    urlAPI: `https://api.themoviedb.org/3/`,
    apiKey: 'api_key=590da24c27c6359cc200c9c215463c46',
    movies: null,
    actu: null,
    urlPict: `http://image.tmdb.org/t/p/w300/`,
    login:false,
    token:null,
    username:null,
    password:null,
    erreur:false,
    infoConnection:null,
    sessionId:null,
    favorite:null
    
  },
  mutations: {
    setMovies(state, payload) {
      state.movies = payload.results
    },
    setLogin(state, payload) {
      state.login = payload.results
    },
  },
  actions: {
    get: async function (context, value) {
      let response = await fetch(`${context.state.urlAPI}search/movie?${context.state.apiKey}&query=${value}`);
      let movies = await response.json();
      console.log(movies);
      // this.$store.state.movies = movies.results; pas bon
      context.commit('setMovies', movies)
    },
    getactually: async function (context, value) {
      console.log(value)
      let response = await fetch(`${context.state.urlAPI}now_playing?${context.state.apiKey}`);
      let actu = await response.json();
      console.log(actu);
    },
    newFavorite: async function (context, value) {
      console.log(value)
      const favorite = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request_token: context.state.token,
        }),
      };
      try {
        const fetchResponseNewFavorite = await fetch(
          `${context.state.urlAPI}account/{account_id}/favorite?${context.state.apiKey}`,
          favorite
        );
       let infoFavorite = await fetchResponseNewFavorite.json();
       context.state.favorite = infoFavorite.session_id ;
        console.log("favoris", context.state.favorite);
        
      } catch (e) {
        return e;
      }
      
    },
    
    // getLogin: async function (context,value) {
    //   console.log(value)
    //   let response = await fetch(`${context.state.urlAPI}authentication/token/new?${context.state.apiKey}`);
    //   let token = await response.json();
    //   console.log(token);
    // },
    


  }
})
