<template>
  <div class="nav">
    <router-link to="/" class="position">Recherche de Film</router-link>
     <!-- |<router-link to="/">Accueil</router-link>| -->
    <!-- <router-link to="/Filmsavoir">Film à voir</router-link>|
    <router-link to="/Favoris">Favoris</router-link> -->
    <input type="text" class="search" @keyup="search($event.target.value)" />
  </div>
</template>


<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "Navebar",

  methods: {
    search(value) {
      this.get(value);
      if (this.$route.name !== "Accueil") {
        this.$router.push({ name: "Accueil" });
      }
    },
    ...mapMutations(["setMovies"]), // mapMutations renvoie a mutations dans le store
    ...mapActions(["get"]),
  },
  props: {},
  computed: mapState(["count"]),
};
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.position{
  margin-left: -200px;
}
.nav {
  background-color: #333940;
  color: #ffffff;
  height: 50px;
  position: relative;
  padding-top: 30px;
  width: 1380px;
}
/* ul li {
  list-style: none;
  padding-left: 30px;
  padding-top: -10px;
}
ul {
  display: flex;
  padding-top: 30px;
  padding-bottom: 20px;
} */

input {
  position: absolute;
  margin-left: 20px;
}
a {
  color: white;
}
</style>
